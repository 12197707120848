
import { defineComponent } from "vue";
import {
  SVG,
  extend as SVGextend,
  Element as SVGElement,
} from "@svgdotjs/svg.js";
import { dom } from "@fortawesome/fontawesome-svg-core";

let t = 0;
let tMs = 0;
let step = 0;
let speed = 0;
let deg = 0;
let lastResult = 0;
let audio: HTMLAudioElement | null = null;
let lastFrameTimestamp = 0;
let currentAudioDuration = 0;

function easeOutCirc(x: number): number {
  return Math.sqrt(1 - Math.pow(x - 1, 2));
}

function easeOutQuad(x: number): number {
  return 1 - (1 - x) * (1 - x);
}

function easeOutExpo(x: number): number {
  return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
}

export default defineComponent({
  name: "WheelOfDestiny",
  data: function () {
    return {
      categories: [
        "Games",
        "Anime & Zeichentrick",
        "Filme & TV",
        "Schnipsel",
        "Game Songs",
        "Movie Songs",
        "Hä?!",
        "Joker"
      ],
      colors: [
        "#d40000",
        "#ff6600",
        "#ffcc00",
        "#aad400",
        "#44aa00",
        "#2ca089",
        "#00aad4",
        "#3737c8",
        "#5a2ca0",
        "#8800aa",
        "#ff00cc",
        "#ff0066",
      ],
    };
  },
  watch: {
    categories: {
      handler: 
        function(val, oldVal) {
          console.log(val);
          // this.$data.categories[0] = val;
          console.log(this.$data);
          this.drawWheel();
        },
      deep: true
    },
  },
  methods: {
    drawRotateAnimation: async function () {
      const currentFrameTimestamp = new Date().getTime();
      lastFrameTimestamp = (lastFrameTimestamp === 0) ? currentFrameTimestamp : lastFrameTimestamp;
      const svg = document.querySelector("svg");

      if(audio === null || !audio?.src.match("zelda_chest_open.ogg")) {
        audio?.load();
        audio = null;
        audio = new Audio("/audio/zelda_chest_open.ogg");
        await audio.play()
        currentAudioDuration = audio.duration * 1000;
        console.log(audio.duration)
      }

      tMs += currentFrameTimestamp - lastFrameTimestamp;
      console.log(tMs)
      t = (tMs === 0) ? 0 : tMs / currentAudioDuration 
      // t = t + step > 1.0 ? 1 : t + step;
      const easeState = easeOutExpo((t > 1) ? 1 : t);
      deg += easeState * (1 - easeState) * speed;

      console.log(
        "t: ",
        t,
        "deg: ",
        deg,
        "step: ",
        step,
        "speed: ",
        speed,
        "easeOutCirc(t): ",
        easeOutCirc(t)
      );

      svg?.setAttribute("style", `transform: rotate(${deg}deg)`);
      if (t < 1) {
        console.log(audio?.src)

        window.requestAnimationFrame(this.drawRotateAnimation);

        const revDeg = deg % 360;
        const data = [...this.$data.categories].filter((val) => val);
        const result = Math.abs(
          Math.floor((revDeg % 180) / (180 / data.length) - (data.length - 1))
        );

        // if(result != lastResult) {
          // lastResult = result
          // const audio = new Audio("/audio/tick2.wav");
          // audio.play();
        // }

        const domEle = document.querySelector("#result_text");
        if (domEle !== null) {
          domEle.innerHTML = data[result];
        }
        console.log(
          "deg: ",
          revDeg % 180,
          "degPart: ",
          180 / data.length,
          "result: ",
          result
        );
        lastFrameTimestamp = currentFrameTimestamp;
      } else {
        document.querySelector("#result")?.classList.remove("inactive")
        document.querySelector("#result")?.classList.add("active")
        if(audio !== null) {
          audio.load()
          audio = new Audio("/audio/zelda_get_item.ogg")
          await audio.play()
        }
        const revDeg = deg % 360;
        const data = [...this.$data.categories].filter((val) => val);
        const result = Math.abs(
          Math.floor((revDeg % 180) / (180 / data.length) - (data.length - 1))
        );
        const domEle = document.querySelector("#result_text");
        if (domEle !== null) {
          domEle.innerHTML = data[result];
        }
        console.log(
          "deg: ",
          revDeg % 180,
          "degPart: ",
          180 / data.length,
          "result: ",
          result
        );

        t = 0;
        tMs = 0;
        step = 0;
        speed = 0;
        deg = deg % 360;
        currentAudioDuration = 0;
        lastFrameTimestamp = 0;
      }
    },
    toggleView: function () {
      const wheel = document.querySelector("#wheel");
      const settings = document.querySelector("#settings");

      console.log();

      if (wheel?.classList.contains("active")) {
        wheel.classList.remove("active");
        wheel.classList.add("inactive");
      } else if (wheel?.classList.contains("inactive")) {
        wheel.classList.remove("inactive");
        wheel.classList.add("active");
        this.drawWheel();
      }

      if (settings?.classList.contains("active")) {
        settings.classList.remove("active");
        settings.classList.add("inactive");
      } else if (settings?.classList.contains("inactive")) {
        settings.classList.remove("inactive");
        settings.classList.add("active");
      }
    },
    rotateWheel: function () {
      if (t == 0) {
        document.querySelector("#result")?.classList.remove("active")
        document.querySelector("#result")?.classList.add("inactive")
        console.log("rotate wheel");
        step = (Math.random() * 25 + 20) / 10000;
        speed = Math.random() * 100 + 10;
        this.drawRotateAnimation();
      }
    },
    drawWheel: function () {
      console.log("drawWheel");
      const wheelNode = document.querySelector("#wheel > svg");
      if (wheelNode !== null) {
        wheelNode.remove();
      }
      console.log(...this.$data.categories);
      const data = [...this.$data.categories].filter((val) => val);
      const colors = [...this.$data.colors];
      const draw = SVG().addTo("#wheel").size(300, 300);
      draw.circle().attr({
        fill: "#999",
        r: "110",
        cx: 150,
        cy: 150,
        stroke: "#555",
        "stroke-width": "5",
        "transform-origin": "center",
        transform: "rotate(90)",
      });

      for (let i = 0; i < data.length * 2; i++) {
        const group = draw.group();
        const text = data[i % data.length];
        let percent = 100 / (data.length * 2);
        let rotate = i > 0 ? 360 * ((i * percent) / 100) : 0;
        rotate += 90;

        group.add(
          draw.circle().attr({
            fill: "transparent",
            stroke: `${colors[i % data.length]}`,
            "transform-origin": "center",
            transform: `rotate(${rotate})`,
            "stroke-width": "110",
            cx: 150,
            cy: 150,
            r: "55",
            "stroke-dasharray": `calc(${percent} * 345.575 / 100) 345.575`,
          })
        );
        group.add(
          draw
            .group()
            .add(
              draw
                .text(text)
                .attr({ x: 45, y: 155 })
                .attr({
                  "transform-origin": "center center",
                  transform: `rotate(${90 / data.length})`,
                })
                .attr({
                  "font-size": 16 - ((text.length/12)) * 6
                })
            )
            .attr({
              "transform-origin": "center center",
              transform: `rotate(${rotate + 180})`,
            })
        );
      }
    },
  },
  created: function () {
    return;
  },
  mounted: function () {
    (this as any).draw = SVG().addTo("#wheel").size(300, 300);

    this.drawWheel();
  },
});
